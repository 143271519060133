/*!

=========================================================
* Material Kit PRO React - v1.9.0 based on Material Kit PRO v2.0.2 (Bootstrap 4.0.0 Final Edition)
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/


// Core Components
@import "core/variables";
@import "core/mixins";
@import "core/fileupload";
@import "core/keyframes";

// Core Plugins
@import "core/misc";

// @import "plugins/plugin-datetime-picker";
@import "plugins/plugin-nouislider";
@import "plugins/plugin-react-datetime";
@import "plugins/plugin-react-image-gallery";
@import "plugins/plugin-react-slick";
@import "plugins/plugin-react-tagsinput";

form.saleForm {
    margin-top: 10px;
    padding: 0;
    text-align: center;
    input {
      padding: 10px;
      border: 1px solid #02f651;
      border-radius: 15px;
      background-color: #000000;
      font-size: 14px;
      font-weight: 300;
      color: #02f651;
      &:focus {
        border: 1px solid #000000;
      }
    }
  }